import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Global } from '../../global/global';
import axios from 'axios';

@Component({
  selector: 'app-homepage-competence',
  templateUrl: './homepage-competence.component.html',
  styleUrls: ['./homepage-competence.component.scss'],
  providers:[ Global ]
})
export class HomepageCompetenceComponent implements OnInit {
  @ViewChild('competence') myDiv: ElementRef;
  @ViewChild('container') container: ElementRef;

  constructor(
    public global:Global,
    private renderer:Renderer2
    ) {

     }
     error=null;
     competences;
     homepage;

     async ngOnInit() {

      try{
        const responseTitle = await axios.get(this.global.API_URL + "/Homepage");
        this.homepage = responseTitle.data;
      } catch(error){
        this.error = error;
      }
    }

}
