import { Component, Renderer2, ViewChild, OnInit } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Global } from '../../global/global';
import { Competence} from '../competences';
import axios from 'axios';
import { SwiperConfigInterface} from 'ngx-swiper-wrapper';


@Component({
  selector: 'app-securisation',
  templateUrl: './securisation.component.html',
  styleUrls: ['./securisation.component.scss'],
  providers: [ Global, Competence ]
})
export class SecurisationComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
 
  constructor( 
    public global:Global,
    private renderer : Renderer2,
    public competence : Competence
    ) {

     }

     async ngOnInit() {
      try{
        const response = await axios.get(this.global.API_URL + "/securisation");
        this.competence.data = response.data;
        this.competence.ngOnInit();
      }
      catch(error){

      }
    }
    config:SwiperConfigInterface = this.competence.config;


}
