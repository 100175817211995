import { Component, Renderer2, ViewChild, OnInit } from '@angular/core';
import { Global } from '../../global/global';
import axios from 'axios';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-equipe',
  templateUrl: './equipe.component.html',
  styleUrls: ['./equipe.component.scss'],
  providers: [ Global ]
})
export class EquipeComponent implements OnInit {

  @ViewChild('collab') collab;

  constructor(
    private global: Global,
    ) {

     }

  error;
  collabs;
  equipeData;

  async ngOnInit() {
    try{
      const response = await axios.get(this.global.API_URL + '/collaborateurs');
      this.collabs = response.data;
      this.collabs.sort(this.compare);
      const respEquipe = await axios.get(this.global.API_URL + '/equipe');
      this.equipeData = respEquipe.data;
    }
    catch (error){
      this.error = error;
    }
  }


  compare(a, b){
    if (a.ordre < b.ordre){
      return -1;
    }
    if (a.ordre > b.ordre){
      return 1;
    }
    return 0;
  }

}
