import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Global } from '../../global/global';
import axios from 'axios';

@Component({
  selector: 'app-talend',
  templateUrl: './talend.component.html',
  styleUrls: ['./talend.component.scss'],
  providers:[ Global ]
})
export class TalendComponent implements OnInit {

  @ViewChild('competence') myDiv: ElementRef;
  @ViewChild('container') container: ElementRef;

  constructor(
    public global:Global,
    private renderer:Renderer2
    ) {

     }
     error=null;
     talend;
     homepage;

     async ngOnInit() {

      try{
        const responseTitle = await axios.get(this.global.API_URL + "/Homepage");
        this.homepage = responseTitle.data;
      } catch(error){
        this.error = error;
      }
    }

}

