import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
@Injectable()
export class Global {
  API_URL = 'https://daka-team.com/back';
  limiteMobile = 1255;
  candidature_spontanee_URL  = 'candidature-spontanee';
  secureToken = 'fd9a5376-8114-42a7-b654-5196a31c184d';
  email = 'abbes.kadmiry@daka-team.com';
}
