<app-navigation-bar></app-navigation-bar>

<section class="vision">
    <div [innerHTML]="visionData?.valeurTitle" #valeurTitle class="section-title  strapi-text ">

    </div>
<div class=" vie">
    <div [innerHTML]="visionData?.valeurDescription" #valeurDescription class="section-description  strapi-text ">

    </div>
</div>
    <div class="valeurs-container-image">
        <div class="valeurs-image">
            <img src="{{global?.API_URL + visionData?.valeursImage.url}}" alt="">
        </div>
    </div>

    <div  class="vision-container " #container>

        <div  class="container-part ">
            <div [innerHTML]="visionData?.partUneTitle" #partUneTitle  class="div-title  strapi-text">

            </div>
            <div [innerHTML]="visionData?.partUneDescription" #partUneDescription class="div-text  strapi-text">

            </div>
        </div>

        <div class="container-part">
            <div [innerHTML]="visionData?.partDeuxTitle" #partDeuxTitle class="div-title  strapi-text">

            </div>
            <div [innerHTML]="visionData?.partDeuxDescription" #partDeuxDescription class="div-text  strapi-text">

            </div>
        </div>
        

        <div class="container-part ">
            <div [innerHTML]="visionData?.partTroisTitle" #partTroisTitle class=" div-title  strapi-text">

            </div>

            <div [innerHTML]="visionData?.partTroisDescription" #partTroisDescription class="div-text  strapi-text">

            </div>
        </div>
    </div>
<div class=" vie">
    <div [innerHTML]="visionData?.engagementTitle" #engagementTitle class="section-title engagement-title strapi-text">

    </div>

    <div [innerHTML]="visionData?.engagementDescription" #engagementDescription class="section-description strapi-text">

    </div>

</div>
    <div class="engagement-image-container">
        <img #engagementImage src="{{global?.API_URL + visionData?.engagementImage.url}}" alt="">
    </div>

</section>

<app-main-footer></app-main-footer>