import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Global } from '../../global/global';
import axios from 'axios';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  providers: [ Global ]

})
export class AboutUsComponent implements OnInit {
  @ViewChild('sectionTitle') sectionTitle: ElementRef;
  @ViewChild('introTitle') introTitle: ElementRef;
  @ViewChild('introText') introText: ElementRef;
  @ViewChild('histoireTitle')histoireTitle: ElementRef;
  @ViewChild('histoireText')histoireText: ElementRef;
  @ViewChild('citationImage') citationImage: ElementRef;
  @ViewChild('citationText') citationText: ElementRef;
  @ViewChild('approcheTitle') approcheTitle: ElementRef;
  @ViewChild('approcheText') approcheText: ElementRef;


  constructor(
    public global: Global
    ) {

     }

  error = null;
  aboutUsData;

  async ngOnInit() {
    try{
      const response = await axios.get(this.global.API_URL + '/about-us');
      this.aboutUsData = response.data;
    }
    catch (error){
      this.error = error;
    }
  }

}
