import { Component,Renderer2,ViewChild, OnInit } from '@angular/core';
import { Global } from '../../global/global';
import { Competence} from '../competences';
import axios from 'axios';
import { SwiperConfigInterface} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-industrialisation',
  templateUrl: './industrialisation.component.html',
  styleUrls: ['./industrialisation.component.scss'],
  providers:[ Global,Competence ]
})
export class IndustrialisationComponent implements OnInit {



  constructor( 
    public global:Global,
    private renderer : Renderer2,
    public competence : Competence
    ) {

     }

     async ngOnInit() {
      try{
        const response = await axios.get(this.global.API_URL + "/industrialisation");
        this.competence.data = response.data;
        this.competence.ngOnInit();
    
      }
      catch(error){

      }
    }
    config:SwiperConfigInterface = this.competence.config;

}
