<section class="competence" #competence>
    <div [innerHTML]="homepage?.competenceTitle" class="section-title strapi-text">
        
        <a href="">
    </a>
    </div>
    <div class="competence-container" #container>
    <!--    <div class="competence-elt">
            <a  routerLink="/what-we-do" routerLinkActive="active" >/architecture-urbanisation
                
                <img src="{{ global.API_URL + homepage?.archi.image.url}}" alt="" class="competence-elt-img">
                <div></div>
                <div class="text-align-center">
                    <h1 [innerHTML]="homepage?.archi.title" class="competence-elt-tilte strapi-text"></h1>
                </div>
            </a>
            
            <p [innerHTML]="homepage?.archi.description" class="competence-elt-text strapi-text">
                
            </p>
        </div>-->
        <div class="competence-elt text-container animated-paragraph left">
            <a routerLink="/what-we-do" routerLinkActive="active" >
                <img src="{{ global.API_URL + homepage?.archi.image.url}}" alt="" class="competence-elt-img">
                <div></div>
                <div class="text-align-center">
                    <h1 [innerHTML]="homepage?.archi.title" class="competence-elt-title strapi-text"></h1>
                </div>
            </a>
            
            <p [innerHTML]="homepage?.data.description" class="competence-elt-text strapi-text">
                
            </p>
        </div>
        <div class="competence-elt text-container animated-paragraph bottom">
            <a routerLink="/what-we-do" routerLinkActive="active" >
                <img src="{{ global.API_URL + homepage?.data.image.url}}" alt="" class="competence-elt-img">
                <div></div>
                <div class="text-align-center">
                    <h1 [innerHTML]="homepage?.data.title" class="competence-elt-title strapi-text"></h1>
                </div>
            </a>
            
            <p [innerHTML]="homepage?.data.description" class="competence-elt-text strapi-text">
                
            </p>
        </div>
        <div class="competence-elt text-container animated-paragraph top">
            <a routerLink="/what-we-do" routerLinkActive="active" >
                <img src="{{ global.API_URL + homepage?.indu.image.url}}" alt="" class="competence-elt-img">
                <div></div>
                <div class="text-align-center">
                    <h1 [innerHTML]="homepage?.indu.title" class="competence-elt-title strapi-text">   </h1>
                </div>
            </a>
            
            <p [innerHTML]="homepage?.indu.description" class="competence-elt-text strapi-text">
                
            </p>
        </div>
        <div class="competence-elt text-container animated-paragraph bottom">
            <a  routerLink="/what-we-do" routerLinkActive="active" >
                <img src="{{ global.API_URL + homepage?.cloud.image.url}}" alt="" class="competence-elt-img">
                <div></div>
                <div class="text-align-center">
                    <h1 [innerHTML]="homepage?.cloud.title" class="competence-elt-title strapi-text"></h1>
                </div>
            </a>
            
            <p [innerHTML]="homepage?.cloud.description" class="competence-elt-text strapi-text">
                
            </p>
        </div>
        <div class="competence-elt text-container animated-paragraph right">
            <a  routerLink="/what-we-do" routerLinkActive="active" >
                <img src="{{ global.API_URL + homepage?.secu.image.url}}" alt="" class="competence-elt-img">
                <div></div>
                <div class="text-align-center">
                    <h1 [innerHTML]="homepage?.secu.title" class="competence-elt-title strapi-text"></h1>
                </div>
            </a>
            
            <p [innerHTML]="homepage?.secu.description" class="competence-elt-text strapi-text">
                
            </p>
        </div>
</div>
    
</section>
