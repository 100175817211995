import { Component, OnInit } from '@angular/core';
import { Global } from '../global/global';
import axios from 'axios';

@Component({
  selector: 'app-nos-services',
  templateUrl: './nos-services.component.html',
  styleUrls: ['./nos-services.component.scss'],
  providers : [Global]

})
export class NosServicesComponent implements OnInit {

  constructor(
    public global: Global
  ) { }

  liens;
  nosServices;

  // tslint:disable-next-line: typedef
  async ngOnInit() {
    const responseLiens = await axios.get(this.global.API_URL + '/liens');
    this.liens = responseLiens.data;
    const responseNosServices = await axios.get(this.global.API_URL + '/services');
    this.nosServices = responseNosServices.data;
  }

  // tslint:disable-next-line: typedef
  scroll(el: HTMLElement) {
    el.scrollIntoView({block: 'start'});
}

}
