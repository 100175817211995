import { Component, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { HttpClientModule } from '@angular/common/http';
import {
  GoogleApiModule,
  GoogleApiService,
  GoogleAuthService,
  NgGapiClientConfig,
  NG_GAPI_CONFIG,
  GoogleApiConfig
} from "ng-gapi";

import { AppComponent } from './app.component';
import { HeroComponent } from './Homepage/hero/hero.component';
import { PresentationComponent } from './Homepage/presentation/presentation.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { HomepageCompetenceComponent } from './Homepage/homepage-competence/homepage-competence.component';
import { HomepageChiffresComponent } from './Homepage/homepage-chiffres/homepage-chiffres.component';
import { TalendComponent } from './Homepage/talend/talend.component';
import { SliderComponent } from './Homepage/slider/slider.component';
// import { SwiperModule } from 'swiper/angular';
import { MainFooterComponent } from './main-footer/main-footer.component';
import { HomepageComponent } from './Homepage/homepage/homepage.component';
import { AboutUsComponent } from './about_us/about-us/about-us.component';
import { VisionComponent } from './about_us/vision/vision.component';
import { EquipeComponent } from './about_us/equipe/equipe.component';
import { WhatWeDoComponent } from './what-we-do/what-we-do.component';
import { ArchiUrbaComponent } from './what-we-do/archi-urba/archi-urba.component';
import { DataComponent } from './what-we-do/data/data.component';
import { mentionsLegalesComponent} from './about_us/mentionsLegales/mentionLegales.component';
import { CloudComponent } from './what-we-do/cloud/cloud.component';
import { IndustrialisationComponent } from './what-we-do/industrialisation/industrialisation.component';
import { SecurisationComponent } from './what-we-do/securisation/securisation.component';
import { PresCollabComponent } from './about_us/pres-collab/pres-collab.component';
import { ReferencesComponent } from './references/references.component';
import { CasUsageUnComponent } from './references/cas-usage/cas-usage-un/cas-usage-un.component';
import { ContactComponent } from './contact/contact.component';
import { CasUsageDeuxComponent } from './references/cas-usage/cas-usage-deux/cas-usage-deux.component';
import { NousRejoindreComponent } from './nous-rejoindre/nous-rejoindre.component';
import { JobComponent } from './nous-rejoindre/job/job.component';
import { MatIconModule } from '@angular/material/icon';
import { PostulerComponent } from './nous-rejoindre/postuler/postuler.component';
import { NosServicesComponent } from './nos-services/nos-services.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';



const appRoutes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'vision', component: VisionComponent },
  { path: 'mentions-legales' , component: mentionsLegalesComponent},
  { path: 'what-we-do', component: WhatWeDoComponent },
  { path: 'architecture-urbanisation', component: ArchiUrbaComponent },
  { path: 'data', component: DataComponent },
  { path: 'cloud', component: CloudComponent },
  { path: 'industrialisation', component: IndustrialisationComponent },
  { path: 'securisation', component: SecurisationComponent },
  { path: 'collab/:id', component: PresCollabComponent },
  { path: 'references', component: ReferencesComponent },
  { path: 'cas-client-un', component: CasUsageUnComponent },
  { path: 'cas-client-deux', component: CasUsageDeuxComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'nous-rejoindre', component: NousRejoindreComponent },
  { path: 'job/:id', component: JobComponent },
  { path: 'postuler/:id', component: PostulerComponent },
  { path: 'services', component: NosServicesComponent }
  
]

let gapiClientConfig: NgGapiClientConfig = {
  client_id: "1050676750374-83fskddqupcjdud7kpdti26ljb1elgva.apps.googleusercontent.com",
  discoveryDocs: ["https://analyticsreporting.googleapis.com/$discovery/rest?version=v4"],
  scope: [
    "https://www.googleapis.com/auth/analytics.readonly",
    "https://www.googleapis.com/auth/analytics"
  ].join(" ")
};


@NgModule({
  declarations: [
    AppComponent,
    HeroComponent,
    PresentationComponent,
    NavigationBarComponent,
    HomepageCompetenceComponent,
    HomepageChiffresComponent,
    TalendComponent ,
    MainFooterComponent,
    HomepageComponent,
    AboutUsComponent,
    VisionComponent,
    mentionsLegalesComponent,
    EquipeComponent,
    WhatWeDoComponent,
    ArchiUrbaComponent,
    DataComponent,
    CloudComponent,
    IndustrialisationComponent,
    SecurisationComponent,
    PresCollabComponent,
    ReferencesComponent,
    CasUsageUnComponent,
    ContactComponent,
    CasUsageDeuxComponent,
    NousRejoindreComponent,
    JobComponent,
    PostulerComponent,
    NosServicesComponent, 
  ],


  imports: [

    BrowserModule,
    // SwiperModule,
    FormsModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',

    }),
    MatIconModule,
    BrowserAnimationsModule,
    CdkAccordionModule,
    MatExpansionModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule,
    // GoogleApiModule.forRoot({
    //   provide: NG_GAPI_CONFIG,
    //   useValue: gapiClientConfig
    // })

  ],


  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
