import { Component,Renderer2,ViewChild, OnInit } from '@angular/core';
import { Global } from '../../global/global';
import { Competence} from '../competences';
import axios from 'axios';
import { SwiperConfigInterface} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-archi-urba',
  templateUrl: './archi-urba.component.html',
  styleUrls: ['./archi-urba.component.scss'],
  providers:[ Global,Competence ]
})
export class ArchiUrbaComponent implements OnInit {


  @ViewChild('partenaires') partenaires;

  constructor( 
    public global:Global,
    private renderer : Renderer2,
    public competence : Competence
    ) {

     }

     async ngOnInit() {
      try{
        const response = await axios.get(this.global.API_URL + "/archi-urba");
        this.competence.data = response.data;
        this.fillElements();
        this.competence.ngOnInit();
      }
      catch(error){

      }
    }
    config:SwiperConfigInterface = this.competence.config;

    
    fillElements = () => {
     
      this.competence.partenaires = this.partenaires;
    }
}
