<app-navigation-bar></app-navigation-bar>

<section class="postuler">
    <div class="main-container">
        <div [innerHTML]="postuler?.title" *ngIf="candidatureSpontanee" class="section-title strapi-text">
            
        </div>
        <div [innerHTML]="job?.title"  *ngIf="! candidatureSpontanee" class="section-title strapi-text">
            
        </div>
            <div class="form-container">
                <div class="form-elt">
                    <form #form [formGroup]="FormGroup" enctype="multipart/form-data"  #formDirective="ngForm" (submit)="onSubmit(f)"  #f="ngForm" class="form">                        <div class="flex-container">

                        <div class="form-inputs">
                        <div  class="form__group field">
                            <input #prenom type="input" class="form__field"  formControlName="prenom"  placeholder="{{postuler?.form.prenom}}" name="prenom" id='prenom' />
                            <label [innerHTML]="postuler?.form.prenom"  for="name" class="form__label  strapi-text">
                                
                            </label>
                          </div>
        
                          <div class="form__group field">
                            <input #nom type="input" class="form__field"  formControlName="nom"  placeholder="{{postuler?.form.nom}}" name="nom" id='nom' required />
                            <div  class="invalid-feedback ">
                                Ce champ ne peut pas être vide
                            </div>
                            <label [innerHTML]="postuler?.form.nom"  for="name" class="form__label  strapi-text">
                                
                            </label>
                          </div>
        
                          <div class="form__group field">
                            <input #numTel type="input" class="form__field"  formControlName="numTel"  placeholder="{{postuler?.form.numTel}}" name="numTel" id='numTel' required />
                            <div class="invalid-feedback">Ce champ ne peut pas être vide</div>
                            <label [innerHTML]="postuler?.form.numTel"  for="name" class="form__label strapi-text">
                                
                            </label>
                          </div>
        
                          <div class="form__group field">
                            <input #email type="input" class="form__field"  formControlName="email"  placeholder="{{postuler?.form.email}}" name="email" id='email' required />
                            <div class="invalid-feedback">Ce champ ne peut pas être vide</div>
                            <label  [innerHTML]="postuler?.form.email" for="name" class="form__label  strapi-text">
                                
                            </label>
                          </div>
        
                    
                          <div class="form__group field">
                            <input #structure type="input" class="form__field"  formControlName="structure"  placeholder="{{postuler?.form.structure}}" name="structure" id='structure' />
                            <label [innerHTML]="postuler?.form.structure"  for="name" class="form__label strapi-text">
                                
                            </label>
                          </div>
        
                          <div class="field message">
                            <textarea  class="" #carac placeholder="{{postuler?.form.message}}"  formControlName="carac"  name="carac" id="carac" cols="30" rows="10" ></textarea>
                            
                            <span #currentCarac class="carac-counter">
                                0
                            </span>
                            /
                            <span [innerHTML]="postuler?.form.nbCaracMax"  #caracMax class="carac-max strapi-text">
                                
                            </span>
                            caractères
                            <div class="invalid-feedback">Ce champ ne peut pas être vide</div>
                          </div>
                       </div>
        
                          <div class="container-encart-cv border-theme">
                            <div class="encart-cv">
                                <div [innerHTML]="postuler?.cv.title"  class="cv-title strapi-text">
                                    
                                </div>
                                <div class="cv-text">
                                    <div [innerHTML]="postuler?.cv.formats"  class="cv-formats strapi-text">
                                        
        
                                    </div>
                                    <div [innerHTML]="postuler?.cv.taille"  class="cv-taille strapi-text">
                                        
        
                                    </div>
                                </div>
                                <div class="cv-elts">
                                    <div class="cv-elt">
                                        <div class="cv-elt-container-image">
                                            <label for="file-input-first">
                                                <img src="{{global?.API_URL + postuler?.cv?.fileImage.url}}" alt="">
                                            </label>
                                            <input id="file-input-first" type="file"
                                            (change)="handleFileFirstInput($event.target.files)" />

                                        </div>
                                       
                                        <div class="cv-elt-container-text">
                                            <div [innerHTML]="fileTextFirst"  class="cv-elt-text strapi-text">
                                                
                                            </div>
                                            <span (click)="removeButtonFirstIsClicked()" class="cv-elt-close">
                                                
                                                &#10006;
                                            </span>
                                        </div>
                                    </div>

                                    <div class="cv-elt">
                                        <div class="cv-elt-container-image">
                                            <label for="file-input-second">
                                                <img src="{{global?.API_URL + postuler?.cv?.fileImage.url}}" alt="">
                                            </label>
                                            <input id="file-input-second" type="file"
                                            (change)="handleFileFSecondInput($event.target.files)" />

                                        </div>
                                       
                                        <div class="cv-elt-container-text">
                                            <div  [innerHTML]="fileTextSecond" class="cv-elt-text strapi-text">
                                                
                                            </div>
                                            <span (click)="removeButtonSecondIsClicked()" class="cv-elt-close">

                                                &#10006;
                                            </span>
                                            
                                        </div>
                                    </div>
 
                                    <div class="container-linked">
                                        <div class="linked-container-image">
                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 382 382" style="enable-background:new 0 0 382 382;" xml:space="preserve">
                                           <path d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889 C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056 H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806 c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1 s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73 c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079 c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426 c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472 L341.91,330.654L341.91,330.654z"/> </svg>
                                        </div>
                                        <div class="container-linkedIn">
                                            <div class="linkedIn">
                                                <div class="linkedIn-container-image">
                                                    <img src="{{global?.API_URL + postuler?.linkedInImage?.url}}" alt="">
                                                </div>
                                                <div class="form__group field linkedIn">
                                                    <input #linkedIn type="input" class="form__field" placeholder=" {{postuler?.linkedInText}}" name="linkedIn" id='linkedIn' />
                                                    <label [innerHTML]="postuler?.linkedInText"  for="linkedIn" class=" strapi-text form__label"> 
                                                       
                                                    </label>
                                                  </div>
                                                <div class="linkedIn-text">
                                                   
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                            </div>
                        </div>
                    </div>
                    <div class="container-flex">
                        <div class="cta-button">
                            <input class="cta-button-elt" type="submit" value="Envoyer"> 
                        </div>
                        
                        <div class="captcha-container">
                            <re-captcha class="captcha"
                        (resolved)="resolved($event)"
                        siteKey="{{siteKey}}"
                        
                      ></re-captcha>
      <div #captchaText class="invalid-feedback captcha-text">Veuillez prouver que vous n'êtes pas un robot.</div>
                    </div>
                        </div>
                        
                          
                    </form>
                </div>
                
            </div>

        <div class="container-annonce">
            <div [innerHTML]="postuler?.annonce_donnee" class="annonce strapi-text">
                
            </div>
        </div>

    </div>
</section>

<app-main-footer></app-main-footer>
