import { Component,Renderer2,ElementRef, OnInit,ViewChild } from '@angular/core';
import { Global } from '../global/global';
import axios from 'axios';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss'],
  providers:[ Global ]
})
export class MainFooterComponent implements OnInit {
@ViewChild("followText") followText :ElementRef;
@ViewChild("arrow") arrow :ElementRef;
@ViewChild("logo") logo :ElementRef;


@ViewChild("tooltip") tooltip:ElementRef;

  constructor(
    public global:Global,
    private renderer : Renderer2,
    private elem: ElementRef
  ) { }
  footerUrl = "/main-footer";
  error=null;
  footerData;
  liens;
  infoDKT;

  async ngOnInit() {
    try{
      const response = await axios.get(this.global.API_URL + this.footerUrl);
      this.footerData = response.data;
      const respLien = await axios.get(this.global.API_URL + "/liens");
      this.liens = respLien.data;
      const respinfoDKT = await axios.get(this.global.API_URL + "/info-dkt");
      this.infoDKT = respinfoDKT.data;
      this.fillData();
    } catch(error){
      this.error = error;
    }
    
  }



  fillData = () => {
    // Logo
    let logoImg = this.logo.nativeElement.querySelector("img");
    let newSrc = this.global.API_URL + this.footerData.logo.url;
    this.renderer.setAttribute(logoImg,"src",newSrc);

    // FollowText
    let newFollowText = this.footerData.follow;
    this.followText.nativeElement.innerText = newFollowText;


  }  

  backToTop = () => {
    window.scrollTo(0,0);
  }

  /*
* Clipboard stuff
*/

 copyMailAddress = () => {
  let adr = document.querySelector("#mail-address") as HTMLElement;
  let adreText = adr.innerText;
  var tempInput = document.createElement("input");
  tempInput.value = adreText;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
}

 toggleTooltipClipboard = () => {
  let tooltip = this.tooltip.nativeElement;
  if(tooltip.style.display=="" || tooltip.style.display=="none"){
      tooltip.style.display="block";
  }
  else{
      tooltip.style.display="";
  }
}

 clipboardIsClicked = () => {
  let tooltip = this.tooltip.nativeElement;
  let text = tooltip.innerText;
  tooltip.innerText = "Copié ! ";
  this.copyMailAddress();
  this.toggleTooltipClipboard();
  setTimeout(this.toggleTooltipClipboard,3000); 
  
  setTimeout(() =>{
    tooltip.innerText = text;
  },3000);
  }

}
