import { Component, OnInit, ElementRef,ViewChild } from '@angular/core';
import { Global } from '../../global/global';
import axios from 'axios';

@Component({
  selector: 'app-mentionsLegales',
  templateUrl: './mentionsLegales.component.html',
  styleUrls: ['./mentionsLegales.component.scss'],
  providers: [ Global ]
})
export class mentionsLegalesComponent implements OnInit {
  @ViewChild('valeurTitle') valeurTitle: ElementRef;
  @ViewChild('valeurDescription') valeurDescription: ElementRef;
  @ViewChild('partUneTitle') partUneTitle: ElementRef;
  @ViewChild('partUneDescription')partUneDescription: ElementRef;
  @ViewChild('partDeuxTitle')partDeuxTitle: ElementRef;
  @ViewChild('partDeuxDescription') partDeuxDescription: ElementRef;
  @ViewChild('partTroisTitle') partTroisTitle: ElementRef;
  @ViewChild('partTroisDescription') partTroisDescription: ElementRef;
  @ViewChild('engagementTitle') engagementTitle: ElementRef;
  @ViewChild('engagementDescription')engagementDescription: ElementRef;
  @ViewChild('engagementImage')engagementImage: ElementRef;
  constructor(
    public global: Global
    ) {

     }

  error = null;
  mentionData;
  
   async ngOnInit(){
    try{
      const response = await axios.get(this.global.API_URL + "/mentions-legales");
      this.mentionData = response.data;
    }
    catch (error){
      this.error = error;
    }
  }



}
