<app-navigation-bar></app-navigation-bar>

<section class="securisation">

    
    <div class="main-container">
 
        <div class="left-side" >

        
            <div *ngFor="let activite of competence?.data?.activite" class="activite">

                <div [innerHTML]="activite.title" class="section-title strapi-text">

                </div>

                <div [innerHTML]="activite.description" class="description strapi-text">

                </div>

                <div  class="retour-exp-container">
                    <div *ngFor="let retourExp of activite.retourExp" class="retour-exp">

                        <div class="retour-exp-img">
                            <img src="{{global?.API_URL + retourExp.logo?.url}}" alt="">

                        </div>
                        <div [innerHTML]="retourExp.descr" class="retour-exp-descr strapi-text"></div>

                    </div>
                </div>

            </div>

            <div class="container-flex">
                <div class="main-image">
                    <img  src="{{global.API_URL + competence?.data?.mainImage?.url}}" alt="">
                </div>
    
            </div>
        </div>
        
        <div #partenaires class="partenaires border-theme">
            <div [innerHTML]="competence?.data?.partenaireTitle"  class="partenaires-title strapi-text"></div>
            
            <div class="div-container">
                <swiper *ngIf="competence.data" [config]="config" >
                  <ng-template swiperSlide *ngFor="let logo of competence.data.partenaires"><img  class="partenaires-image"  [src]="global.API_URL + logo.url" alt=""></ng-template>
                  
                </swiper>
              </div>
            

        </div>
    
</div>
    
</section>

<app-main-footer></app-main-footer>