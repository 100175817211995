<section class="presentation-daka">
    <div class="section-title">
        <a [innerHTML]="homepage?.presentationTitle" class=" strapi-text"routerLink="/about-us" routerLinkActive="active" >
       
    </a>
    </div>
    <div *ngIf="notmobile" 
      [innerHTML]="homepage?.presentationDescription" 
      class="presentation-daka-descr droite diplaydesc justify strapi-text">
    </div>
      <mat-accordion *ngIf="mobile">
        <mat-expansion-panel hideToggle> 
        </mat-expansion-panel>
        <mat-expansion-panel 
          (opened)="panelOpenState1 = true"
          (closed)="panelOpenState1 = false">
          <mat-expansion-panel-header>
            <mat-panel-title 
              [innerHTML]="homepage?.presentationDescriptiontitle1" 
              [ngClass]="panelOpenState1  == true ? 'traitafter': 'trait'"
              class="example-accordion-item-header ecriture "
            >  
            </mat-panel-title> 
          </mat-expansion-panel-header>
          <div [innerHTML]="homepage?.presentationDescription1" class="presentation-daka-descr droite  justify strapi-text">
          </div>
        </mat-expansion-panel>
      
        <mat-expansion-panel (opened)="panelOpenState2 = true"
                            (closed)="panelOpenState2 = false">
          <mat-expansion-panel-header>
            <mat-panel-title 
            [innerHTML]="homepage?.presentationDescriptiontitle2"
            [ngClass]="panelOpenState2  == true ? 'traitafter': 'trait'" 
            class=" trait example-accordion-item-header  ecriture"
            >
              
            </mat-panel-title>
            
          </mat-expansion-panel-header>
          <div [innerHTML]="homepage?.presentationDescription2" class="presentation-daka-descr justify droite strapi-text"></div>
        </mat-expansion-panel>
        <mat-expansion-panel 
          (opened)="panelOpenState3 = true"
          (closed)="panelOpenState3 = false">
          <mat-expansion-panel-header>
            <mat-panel-title 
              [innerHTML]="homepage?.presentationDescriptiontitle3 "
              [ngClass]="panelOpenState3  == true ? 'traitafter': 'trait'" 
              class=" example-accordion-item-header ecriture"
            >
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [innerHTML]="homepage?.presentationDescription3" class="presentation-daka-descr justify droite strapi-text"></div>
      </mat-expansion-panel>
      </mat-accordion>
      
</section>
