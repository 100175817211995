import { Component, OnInit } from '@angular/core';
import { Global } from '../../../global/global';
import axios from 'axios';
@Component({
  selector: 'app-cas-usage-un',
  templateUrl: './cas-usage-un.component.html',
  styleUrls: ['./cas-usage-un.component.scss'],
  providers : [Global]
})
export class CasUsageUnComponent implements OnInit {

  constructor(
    public global : Global
  ) { }

  casClient;

  async ngOnInit() {
    const responseRef = await axios.get(this.global.API_URL + "/references");
    this.casClient = responseRef.data.casClientUn;
  }

}
