<app-navigation-bar></app-navigation-bar>

<section class="contact">
    <div [innerHTML]="contact?.sectionTitle" class="section-title strapi-text">
        
    </div>
    <div class="container-flex">
        <div class="container-form">
            <form #form [formGroup]="FormGroup"   #formDirective="ngForm" (submit)="onSubmit(f)"  #f="ngForm" class="form">
                <div  class="form__group field">
                    <input #prenom type="input" class="form__field" placeholder="{{contact?.form.prenom}}" formControlName="prenom" name="prenom" id='prenom' />
                    <label [innerHTML]="contact?.form.prenom" for="name" class="form__label strapi-text">
                        
                    </label>
                  </div>

                  <div class="form__group field">
                    <input #nom type="input" class="form__field" placeholder="{{contact?.form.nom}}" formControlName="nom"  name="nom" id='nom' required />
                    <div class="invalid-feedback ">Ce champ ne peut pas être vide</div>
                    <label [innerHTML]="contact?.form.nom" for="name" class="form__label strapi-text">
                        
                    </label>
                  </div>

                  <div class="form__group field">
                    <input #numTel type="input" class="form__field" placeholder="{{contact?.form.numTel}}" formControlName="numTel"  name="numTel" id='numTel' required />
                    <div class="invalid-feedback">Ce champ ne peut pas être vide</div>
                    <label [innerHTML]="contact?.form.numTel" for="name" class="form__label strapi-text">
                        
                    </label>
                  </div>

                  <div class="form__group field">
                    <input #email type="input" class="form__field" placeholder="{{contact?.form.email}}" formControlName="email" name="email" id='email' required />
                    <div class="invalid-feedback">Ce champ ne peut pas être vide</div>
                    <label [innerHTML]="contact?.form.email" for="name" class="form__label strapi-text">
                        
                    </label>
                  </div>

            
                  <div class="form__group field">
                    <input #structure type="input" class="form__field" placeholder="{{contact?.form.structure}}" formControlName="structure"  name="structure" id='structure' />
                    <label [innerHTML]="contact?.form.structure" for="name" class="form__label strapi-text">
                        
                    </label>
                  </div>

                  <div class="field message">
                    <textarea class="" #carac placeholder="{{contact?.form.message}}" formControlName="carac" name="carac" id="carac" cols="30" rows="10" ></textarea>
                    
                    <span #currentCarac class="carac-counter">
                        0
                    </span>
                    /
                    <span [innerHTML]="contact?.form.nbCaracMax" #caracMax class="carac-max strapi-text">
                        
                    </span>
                    caractères
                    <div class="invalid-feedback">Ce champ ne peut pas être vide</div>
                  </div>

                  <div class="container-flex">
                    <div class="cta-button">
                        <input class="cta-button-elt" type="submit" value="Envoyer"> 
                    </div>
                    
                    <div class="captcha-container">
                        <re-captcha class="captcha"
                    (resolved)="resolved($event)"
                    siteKey="{{siteKey}}"
                    
                  ></re-captcha>
  <div #captchaText class="invalid-feedback captcha-text">Veuillez prouver que vous n'êtes pas un robot.</div>
                </div>
                    </div>


            </form>
           
            
            
        </div>
        <div class="infos-container">
            <div class="infos border-theme">
                <div [innerHTML]="contact?.infosDKTTitle" class="infos-title strapi-text">
                    
                </div>
                <div class="infos-container">
                    <div class="information telephone">
                        <div class="information-container-image">
                            <img src="{{ global.API_URL + infoDKT?.telLogoDark.url}}" alt="">
                        </div>
                        <div [innerHTML]="infoDKT?.numTel" class="information-text telephone-text strapi-text">
                            
                        </div>
                    </div>
                    <div class="information email">
                        <div class="information-container-image">
                            <img src="{{ global.API_URL + infoDKT?.emailLogoDark.url}}" alt="">
                        </div>
                        <div [innerHTML]=" infoDKT?.email" class="information-text email-text strapi-text">
                           
                        </div>
                    </div>
                    <div class="information adresse">
                        <div class="information-container-image">
                            <img src="{{ global.API_URL + infoDKT?.adresseLogoDark.url}}" alt="">
                        </div>
                        <div [innerHTML]="infoDKT?.adresse" class="information-text adresse-text strapi-text">
                            
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
    </div>
   <div class="map-container">
        <div id="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2529.418498704316!2d3.071440576950514!3d50.65648987250675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c32a6ba88625b3%3A0x31cb6506e3d0c84c!2s192%20Rue%20du%20G%C3%A9n%C3%A9ral%20de%20Gaulle%2C%2059110%20La%20Madeleine!5e0!3m2!1sfr!2sfr!4v1695909924342!5m2!1sfr!2sfr" width="100%" height="450" style="border:0;" allowfullscreen=""></iframe> 
          <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2532.6508323535836!2d3.079679851489861!3d50.59644288461472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c2d432ba55ec93%3A0x5494ff352b3cd7c5!2s127%20Avenue%20du%20G%C3%A9n%C3%A9ral%20Leclerc%2C%2059155%20Faches-Thumesnil!5e0!3m2!1sfr!2sfr!4v1624461682489!5m2!1sfr!2sfr" width="100%" height="450" style="border:0;" allowfullscreen="" ></iframe> -->
        </div>
    </div>
</section>

<app-main-footer></app-main-footer>

