
<section class="competence" #competence>
  <!-- <div [innerHTML]="homepage?.provi.title" class="section-title strapi-text"> -->
      
    
         
  
  <!-- </div> -->
  <div class="competence-elt">
    <div class="box">
       <img  src="{{ global.API_URL + homepage?.talendimage.url}}"    alt="" class="competence-elt-img">
    </div>
    <div class="box">    
      <p [innerHTML]="homepage?.description" class="competence-elt-text strapi-text "></p>
    </div>

        
    
</div>
</section>

