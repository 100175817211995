<app-navigation-bar></app-navigation-bar>

<section class="references">
    <div [innerHTML]="references?.casClientTitle" class="section-title  strapi-text">
        
    </div>

    <div class="container-cas-client">
        <div class="container-cas-image">
            <a routerLink="/cas-client-un" routerLinkActive="active">
            <img  src="{{global.API_URL + references?.casClientUn.logo.url}}" alt="" class="cas-client">
        </a>
        <div class="en-savoir-plus">
            <div class="en-savoir-plus-lien">
                <a class="button arrow" routerLink="/cas-client-un">
                    En savoir plus
                    
                </a>
                
            </div>
        </div>
        </div>
        <div class="container-cas-image">
            <a routerLink="/cas-client-deux" routerLinkActive="active">
            <img src="{{global.API_URL + references?.casClientDeux.logo.url}}" alt="" class="cas-client">
        </a>
        <div class="en-savoir-plus">
            <div class="en-savoir-plus-lien">
                <a class="button arrow" routerLink="/cas-client-un">
                    En savoir plus
                    
                </a>
                
            </div>
        </div>
        </div>
        
    </div>

    <div class="container-confiance">
        <div [innerHTML]="references?.confianceTitle" class="confiance-title section-title strapi-text">
            
        </div>
        <div *ngIf="partenairesLogos" class="container-confiance-image">
            <div *ngFor="let logo of partenairesLogos[0].images" class="confiance-image">
            <div class="confiance-image-container">
                <img src="{{global.API_URL + logo.url}}" alt="">
            </div>
        </div>
    </div>
    <div class="div-container">
        <swiper *ngIf="partenairesLogos" [config]="config" >
          <ng-template swiperSlide *ngFor="let logo of partenairesLogos[0].images"><img [src]="global.API_URL + logo.url" alt=""></ng-template>
          
        </swiper>
      </div>
    </div>

</section>
<app-main-footer></app-main-footer>