import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Global } from '../../global/global';
import axios from 'axios';
import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { GoogleApiService } from 'ng-gapi';


  declare let Email : any;


@Component({
  selector: 'app-postuler',
  templateUrl: './postuler.component.html',
  styleUrls: ['./postuler.component.scss'],
  providers : [Global]
})
export class PostulerComponent implements OnInit {

  @ViewChild('currentCarac') currentCarac:ElementRef; 
  @ViewChild('carac') carac:ElementRef; 
  @ViewChild('prenom') prenom:ElementRef; 
  @ViewChild('nom') nom:ElementRef; 
  @ViewChild('numTel') numTel:ElementRef;
  @ViewChild('email') email:ElementRef;
  @ViewChild('structure') structure:ElementRef;
  @ViewChild('form') form: ElementRef;
  @ViewChild('linkedIn') linkedIn: ElementRef;
  @ViewChild('captchaText') captchaText:ElementRef;

  siteKey = '6LcNzJocAAAAAFH-TleMkEDaHmEMAWC3Nn0A3QtU';
  captchaResolved:boolean = false;
  public FormGroup:UntypedFormGroup;


  constructor(
    public global : Global,
    private route: ActivatedRoute,
    public formBuilder: UntypedFormBuilder,

  ) {}
  

  idJob:string;
  job;
  postuler;
  candidatureSpontanee:boolean = true;
  fileToUploadFirst: File | null = null;
  fileToUploadSecond: File | null = null;
  fileTextFirst:string;
  fileTextSecond:string;

  async ngOnInit() {
     this.FormGroup = this.formBuilder.group({
      recaptcha : ['',Validators.required],
      email: [null,Validators.required],
      prenom: [null,Validators.required],
      nom: [null,Validators.required],
      numTel: [null,Validators.required],
      structure: [null,Validators.required],
      carac: [null,Validators.required],
      file: [null,Validators.required],
      linkedIn: [null,Validators.required]
    }) 

    // On récure l'id
    this.idJob = this.route.snapshot.paramMap.get("id");

    if( this.idJob !== this.global.candidature_spontanee_URL ){
      // Nous ne sommes pas dans une candidature spontanée, donc on récupère le job.
      const responseJobs = await axios.get(this.global.API_URL + "/jobs/"+this.idJob);
      this.job = responseJobs.data;
      this.candidatureSpontanee=false;
    }

    const responsePostuler = await axios.get(this.global.API_URL + "/postuler");
    this.postuler = responsePostuler.data;
    this.fileTextFirst = this.postuler.cv.fileText;
    this.fileTextSecond = this.postuler.cv.fileText;
    this.capMaxCharacters();
    this.carac.nativeElement.addEventListener('keyup',this.countCharacters,false);

  }

  resolved(response: string) {
    this.captchaResolved = true;
  }

  captchaCheck(){
    let captchaText = document.querySelector(".captcha-text");
    if(! this.captchaResolved){
      captchaText.classList.add('display');
    }
    else{
      captchaText.classList.remove('display');

    } 
    return this.captchaResolved;
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


  async onSubmit(f: NgForm) {
    let checkForm = this.formCheck(f,[this.carac,this.email,this.numTel,this.nom]);
    let checkCaptcha = this.captchaCheck();
    let check = checkForm && checkCaptcha;   

    let jobTitle;
    
    if( this.idJob !== this.global.candidature_spontanee_URL ){
      jobTitle = this.job.title;
    }
    else{
      jobTitle = 'Candidature spontanée';
    }
    let currentUrl = window.location.href;
 
  let attachments=[];

  if(this.fileToUploadFirst){
    let dataUriFirstFile = await this.toBase64(this.fileToUploadFirst);

    attachments.push({
      name:this.fileToUploadFirst.name,
      data:dataUriFirstFile
    });
  }

  if(this.fileToUploadSecond){
    let dataUriSecondFile = await this.toBase64(this.fileToUploadSecond);

    attachments.push({
      name:this.fileToUploadSecond.name,
    data:dataUriSecondFile
    });
  }

    if(check){
      Email.send({
        //SecureToken:'43bd863e-20db-4013-98e3-3d1db126d9d7',
        SecureToken:this.global.secureToken,
        To:this.global.email,
        From : this.global.email,
        Subject :  ` Message de ${this.prenom.nativeElement.value} ${this.nom.nativeElement.value}  `,
        Body : `
        Provenance : ${currentUrl} <br>
        Conernant le job : ${jobTitle} <br>
        Prénom : ${this.prenom.nativeElement.value} <br> 
        Nom : ${this.nom.nativeElement.value}  <br>
        Numéro de téléphone : ${this.numTel.nativeElement.value}  <br>
        Email : ${this.email.nativeElement.value}  <br>
        LinkedIn : ${this.linkedIn.nativeElement.value} <br>
        Structure : ${this.structure.nativeElement.value}  <br>
        Message : ${this.carac.nativeElement.value} <br>
         `,
         Attachments:attachments
        }).then( message => {alert("Votre candidature a bien été envoyée"); f.resetForm();  this.form.nativeElement.reset();
        this.removeButtonFirstIsClicked();
        this.removeButtonSecondIsClicked();
        } );

      
      }
  }

  formCheck(f: NgForm,eltsToCheck:ElementRef[]){
    let check = true;
    eltsToCheck.forEach(elt =>{
      if( ! elt.nativeElement.value){
        check = false;
        this.displaySiblingInvalideFeedback(elt,true);
      }
      else{
        this.displaySiblingInvalideFeedback(elt,false);
      }
    });
    return check;
  }

  notFilledClicked(elt: ElementRef){
    elt.nativeElement.classList.add("not-filled-clicked");
    elt.nativeElement.classList.remove("not-filled");
  }


  handleFileFirstInput(files: FileList) {
    this.fileToUploadFirst = files.item(0);
    this.fileTextFirst = files.item(0).name;
  }

  handleFileFSecondInput(files: FileList) {
  this.fileToUploadSecond = files.item(0);
  this.fileTextSecond = files.item(0).name;
}

  removeButtonFirstIsClicked(){
    this.fileTextFirst = "Aucun Fichier";
    this.fileToUploadFirst = null;
  }

  removeButtonSecondIsClicked(){
    this.fileTextSecond = "Aucun Fichier";
    this.fileToUploadSecond = null;
  }

  displaySiblingInvalideFeedback(elt:ElementRef,check:Boolean){
    let parent = elt.nativeElement.parentElement;
    let invalidFeedback = parent.querySelector(".invalid-feedback");
    if(check){
      invalidFeedback.classList.add("display");
    }
    else{
      invalidFeedback.classList.remove("display");
    }
  }  

  capMaxCharacters = () => {
    this.carac.nativeElement.maxLength = this.postuler.form.nbCaracMax;
  }

  countCharacters = () => {
    let textEntered = this.carac.nativeElement.value.length;
    this.currentCarac.nativeElement.textContent = textEntered;
  }

 
}
