import { Component, Renderer2, ViewChild, OnInit } from '@angular/core';
import { Global } from '../global/global';
import axios from 'axios';
import { AppModule } from 'src/app/app.module';

@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.scss'],
  providers:[ Global ]
})
export class WhatWeDoComponent implements OnInit {

  @ViewChild('competence') competence;
  @ViewChild('sectionTitle') sectionTitle;
  @ViewChild('sectionDescription') sectionDescription;


  
  constructor(
    public global: Global,
    private renderer: Renderer2

    ) {
     }

  error;
  dataSection;
  competences;

  async ngOnInit() {

    try{
      const respSection = await axios.get(this.global.API_URL + '/what-we-do');
      this.dataSection = respSection.data;
    }

    catch (error){
      this.error = error;
    }
  }
}
