<footer class="main-footer">
    <div class="main-footer-container">
        <div class="main-footer-brand">
            <div class="main-footer-brand-container">
                <a #logo class="" href="#"><img src="" alt=""></a>
            </div>
        </div>
        <div class="brand-socials-container">

            <div class="main-footer-socials">
                <h1 #followText class="main-footer-socials-title">

                </h1>
                <a class="" target="_blank"
                    href="https://www.linkedin.com/company/daka%E2%80%A2tec/?originalSubdomain=fr"><svg version="1.1"
                        id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px" viewBox="0 0 382 382" style="enable-background:new 0 0 382 382;"
                        xml:space="preserve">
                        <path
                            d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889 C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056 H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806 c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1 s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73 c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079 c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426 c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472 L341.91,330.654L341.91,330.654z" />
                    </svg>
                </a>
            </div>
            <div class="legal-notice">
                <p class="copyright">
                    &#169; 2022 Copyright DAKA-Team
                </p>
                <a routerLink="/mentions-legales" routerLinkActive="active">
                    {{liens?.presDKT.Titre}} Mentions légales         
                </a>
            </div>
        </div>
        <div class="site-plan-container">
            <a (click)="backToTop()" id="back-to-top">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
                </svg>

            </a>

            <div class="site-plan">
                <div class="site-plan-elt">
                    <h2 class="site-plan-elt-title">
                        {{liens?.presDKT.title}} </h2>
                    <p class="site-plan-elt-link">
                        <a routerLinkActive="active">{{liens?.presDKT.adn}}</a><!-- routerLink="/about-us" -->
                    </p>
                    <p class="site-plan-elt-link">
                        <a  routerLinkActive="active">
                            {{liens?.presDKT.valeurs}}</a><!--routerLink="/vision"-->
                    </p>
                    <!--<p class="site-plan-elt-link">
                        <a routerLink="/equipe" routerLinkActive="active">
                            {{liens?.presDKT.equipe}}
                        </a>
                    </p>-->
                </div>

                <div class="site-plan-elt">
                    <h2 class="site-plan-elt-title">
                        <a  routerLinkActive="active">
                            {{liens?.presCompetences.title}}
                        </a><!-- routerLink="/what-we-do"-->
                    </h2>
                    <p class="site-plan-elt-link">
                        <a  routerLinkActive="active">
                            {{liens?.presCompetences.archi}}</a><!--routerLink="/architecture-urbanisation"-->
                    </p>
                    <p class="site-plan-elt-link">
                        <a  routerLinkActive="active">
                            {{liens?.presCompetences.data}}
                        </a><!--routerLink="/data"-->
                    </p>
                    <p class="site-plan-elt-link">
                        <a  routerLinkActive="active">
                            {{liens?.presCompetences.cloud}}</a><!--routerLink="/cloud"-->
                    </p>
                    <p class="site-plan-elt-link">
                        <a  routerLinkActive="active">
                            {{liens?.presCompetences.indu}}</a><!--routerLink="/industrialisation"-->
                    </p>
                    <p class="site-plan-elt-link">
                        <a  routerLinkActive="active">
                            {{liens?.presCompetences.secu}}</a><!--routerLink="/securisation"-->
                    </p>
                </div>
                <div class="site-plan-elt">
                    <h2 class="site-plan-elt-title">
                        <a  routerLinkActive="active">
                            {{liens?.services.title}}
                        </a><!--routerLink="/services"-->
                    </h2>
                    <p class="site-plan-elt-link">
                        <a >
                     Conseil
                        </a><!--href="/services#conseil"-->
                    </p>
                    <p class="site-plan-elt-link">
                        <a >
                            <!--{{liens?.services.expertise}}-->
                            Expertise
                        </a><!--href="/services#expertise"-->
                    </p>
                    <p class="site-plan-elt-link">
                        <a >
                           <!-- {{liens?.services.solutions}}-->
                           Fabric
                        </a><!--href="/services#solutions"-->
                    </p>
                    <p class="site-plan-elt-link">
                        <a >
                            Formations
                        </a><!--href="/services#formations"-->
                    </p> 
                </div>
                <div class="site-plan-elt">
                    <h2 class="site-plan-elt-title">
                        <a  routerLinkActive="active">
                            Portail
                        </a><!--routerLink="/services"-->
                    </h2>
                   
                </div>
                <!--<div class="site-plan-elt">
                    <h2 class="site-plan-elt-title">
                        <a routerLink="/references" routerLinkActive="active">
                            {{liens?.references.title}}</a>

                    </h2>
                    <h2 class="site-plan-elt-title">
                        <a routerLink="/nous-rejoindre" routerLinkActive="active">
                            {{liens?.rejoindre.title}}
                        </a>
                    </h2>
                    <h2 class="site-plan-elt-title">
                        <a href="">
                            {{liens?.compte.title}}
                        </a>
                    </h2>
                </div>-->
            </div>
        </div>


        <div class="footer-contact-container">
            <a routerLink="/contact" routerLinkActive="active">
                <h1 class="footer-contact-title">
                    Contact
                </h1>
            </a>
            <div class="footer-contact-info">
                <div class="footer-contact-info-item">
                    <img src="{{ global.API_URL + infoDKT?.telLogo.url}}" alt="">
                    <p>
                        {{infoDKT?.numTel}}
                    </p>
                </div>
                <div class="footer-contact-info-item">
                    <img src="{{ global.API_URL + infoDKT?.emailLogo.url}}" alt="">
                    <p id="mail-address">
                        {{infoDKT?.email}}
                    </p>
                    <div id="clipboard-container" style="position:relative">
                        <svg (click)="clipboardIsClicked()" id="clipboard" version="1.1" fill="white" height="24px"
                            width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px" y="0px" viewBox="0 0 488.3 488.3" style="enable-background:new 0 0 488.3 488.3;"
                            xml:space="preserve">
                            <path
                                d="M314.25,85.4h-227c-21.3,0-38.6,17.3-38.6,38.6v325.7c0,21.3,17.3,38.6,38.6,38.6h227c21.3,0,38.6-17.3,38.6-38.6V124C352.75,102.7,335.45,85.4,314.25,85.4z M325.75,449.6c0,6.4-5.2,11.6-11.6,11.6h-227c-6.4,0-11.6-5.2-11.6-11.6V124c0-6.4,5.2-11.6,11.6-11.6h227c6.4,0,11.6,5.2,11.6,11.6V449.6z" />
                            <path
                                d="M401.05,0h-227c-21.3,0-38.6,17.3-38.6,38.6c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5c0-6.4,5.2-11.6,11.6-11.6h227c6.4,0,11.6,5.2,11.6,11.6v325.7c0,6.4-5.2,11.6-11.6,11.6c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5c21.3,0,38.6-17.3,38.6-38.6V38.6C439.65,17.3,422.35,0,401.05,0z" />
                        </svg>
                        <span #tooltip id="custom-tooltip">Cliquez pour copier</span>
                    </div>


                </div>
               <!-- <div class="footer-contact-info-item">
                    <img src="{{ global.API_URL + infoDKT?.adresseLogo.url}}" alt="">
                    <a target="_blank"
                        href="https://www.google.com/maps/dir//Tour+Eiffel,+Champ+de+Mars,+5+Avenue+Anatole+France,+75007+Paris/@48.8584213,2.259462,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47e66e2964e34e2d:0x8ddca9ee380ef7e0!2m2!1d2.2944813!2d48.8583701!3e0?hl=fr">
                        <p>
                            {{infoDKT?.adresse}}
                        </p>
                    </a>

                </div>-->
            </div>
        </div>
    </div>

</footer>