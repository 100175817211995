import { Component, OnInit, ViewChild} from '@angular/core';
import { Global } from '../../global/global';
import {MatExpansionModule} from '@angular/material/expansion';
import axios from 'axios';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss'],
  providers: [ Global ]
})
export class PresentationComponent implements OnInit {
  panelOpenState1 = false;
  panelOpenState2 = false;
  panelOpenState3 = false;
mobile;
notmobile;
  expandedIndex = 0;
  constructor(
    private global: Global
    ) {

     }
     error = null;
     homepage;
     // tslint:disable-next-line: typedef
     async ngOnInit() {
      if (window.screen.width <= 700) { // 768px portrait
        this.mobile = true;
      }
      if (window.screen.width >= 800) { // 768px portrait
        this.notmobile = true;
      }
      try{
        const response = await axios.get(this.global.API_URL + '/Homepage');
        this.homepage = response.data;
      } catch (error){
        this.error = error;
      }
      this.setupText();
    }

    setupText = () => {
      const title = document.querySelector('.presentation-daka .section-title a') as HTMLElement;
      title.innerText = this.homepage.presentationTitle ;
      const descr = document.querySelector('.presentation-daka-text') as HTMLElement;
      // descr.innerText = this.homepage.presentationDescription;

    }

}
