import { Component, OnInit } from '@angular/core';
import { Global } from '../global/global';
import axios from 'axios';
import { SwiperConfigInterface} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss'],
  providers : [Global]
})
export class ReferencesComponent implements OnInit {

  constructor(
    public global : Global
  ) { }

  references;
  partenairesLogos;

  async ngOnInit() {
    const responseRef = await axios.get(this.global.API_URL + "/references");
    this.references = responseRef.data;
    const responsePartenairesLogos = await axios.get(this.global.API_URL + "/partenaires-logos");
      this.partenairesLogos = responsePartenairesLogos.data;
  }

  config:SwiperConfigInterface ={
    slidesPerView: 1,
    scrollbar: false,
    navigation: true,
    loop:true,
    autoplay:{
      delay:1000
    },
    pagination: {
      clickable:true
    },
    breakpoints:{
      650:{
        slidesPerView:2
      },
      1200:{
        slidesPerView:4
      }
    }
  }
 
 // fontions pour le carousel
  onSwiper(swiper) {
    
  }
  onSlideChange() {
    
  }
}
