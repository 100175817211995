import { Component, OnInit } from '@angular/core';
import { Global } from '../global/global';
import axios from 'axios';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  providers:[ Global ],
  encapsulation: ViewEncapsulation.None

})
export class NavigationBarComponent implements OnInit {

  constructor(
    private global: Global
    ) { }

    error = null;
    navBar;
    logoWhiteSrc = '';
    logoDarkSrc = '';
    liens;
    async ngOnInit() {
    try{
      const response = await axios.get(this.global.API_URL + "/Navigation-bar");
      this.navBar = response.data;
      const respLien = await axios.get(this.global.API_URL + "/liens");
      this.liens = respLien.data;
    } catch(error){
      this.error = error;
    }
    
    setup();
    this.setupLogo();
    
  }

  setupLogo = () => {

    const newUrlDark = this.global.API_URL + this.navBar.logoDark.url;
    this.logoDarkSrc = newUrlDark; 
    const newUrlWhite = this.global.API_URL + this.navBar.logoWhite.url;
    this.logoWhiteSrc = newUrlWhite; 
  }

  
}

let modal;
let navbarBrand;
let hamburger;
let menuMobile;

function setup(){
    hamburger = document.querySelector(".hamburger");
    //modal = document.querySelector(".modal");
    hamburger.addEventListener("click",hamburgerIsClicked);
    navbarBrand= document.querySelector(".navbar-brand");
    menuMobile = document.querySelector(".navbar-container");
    menuMobileClickOnLink();
    clickOnDropdownSetup();
}


const menuMobileclose = () => {
  if(window.innerWidth >= 1255 ){
    if((menuMobile.style.display != "none") && hamburger.classList.contains("is-active")){
        hamburgerIsClicked();            
    }
}
}


const menuMobileToggle = ()=>{
  if(menuMobile.style.display=="none"){
    menuMobile.style.display="block";
}
else{
    if(menuMobile.style.display==""){
      menuMobile.style.display="block";
    }
    else
    menuMobile.style.display="none";
}
}

function hamburgerToggle(){
  hamburger.classList.toggle("is-active");
}

function hamburgerIsClicked(){
  hamburgerToggle();
  menuMobileToggle();
  //bodyOverflowToggle();
}

const menuMobileClickOnLink = () => {
  let anchors = document.querySelectorAll(".navbar-container a");
  anchors.forEach( elt => {
    if(!  elt.classList.contains("is-disabled")){
      elt.addEventListener("click",hamburgerIsClicked);
    }
  });
}


const toggleMarinParent = (elt) => {
  let parent = elt.parentElement;
  if(parent.style.marginBottom == ""){
    parent.style.marginBottom = "30px";
  }
  else{
    parent.style.marginBottom="";
  }
  
}


const clickOnDropdownSetup = () => {
  let dropdownList = document.querySelectorAll(".dropdown-list");
  dropdownList.forEach( elt =>{
    if(elt.querySelector(".dropdown-content a") != null){
      elt.addEventListener("click",() => menuToggleDropdown(elt));
      elt.addEventListener("click",()=> toggleIsActive(elt));
      elt.addEventListener("click", () => toggleMarinParent(elt));
    }
  });
}

const toggleIsActive = (elt) => {
  if(window.innerWidth < 1255){
    if(elt.classList.contains("is-active")){
      elt.classList.remove("is-active");
    }
    else{
      elt.classList.add("is-active");
    }
  }
  
}

const closeAllMenuDropdown = () => {
  let dropdown = document.querySelectorAll<HTMLElement>(".dropdown-content");
  dropdown.forEach(element => {
    element.style.display="";
  });
}

const menuToggleDropdown = (dropdownButton) => {
  let dropdown = dropdownButton.querySelector(".dropdown-content");
  if(window.innerWidth < 1255 ){
    if(dropdown.style.display=="" || dropdown.style.display == "none" ){
      dropdown.style.display = "block";
    }
    else {
      dropdown.style.display = "";
    }
  }
  
  
}