<app-navigation-bar></app-navigation-bar>
<section class="about-us">
    <div [innerHTML]="aboutUsData?.title"  class="section-title strapi-text">

    </div>

    <div class="about-us-container" >

        <div class="container-part intro">
            <div [innerHTML]="aboutUsData?.introTitle"  class="div-title intro-title strapi-text">

            </div>
            <div [innerHTML]="aboutUsData?.introDescription"  class="div-text info-title intro-text strapi-text">

            </div>
        </div>

        <div class="container-part histoire">
            <div [innerHTML]="aboutUsData?.histoireTitle"  class="div-title histoire-title strapi-text">

            </div>
            <div [innerHTML]="aboutUsData?.histoireDescription"  class="div-text histoire-text strapi-text">

            </div>
        </div>
        
        <div class="container-part citation">
            <div class="citation-image-container">
                <img  class="citation-image" src="{{global?.API_URL + aboutUsData?.fondateurImage.url}}" alt="">
            </div>

            <div [innerHTML]="aboutUsData?.fondateurCitation"  class="div-text citation-text strapi-text">

            </div>
        </div>

        <div class="container-part approche">
            <div [innerHTML]="aboutUsData?.approcheTitle"  class=" div-title approche-title strapi-text">

            </div>

            <div [innerHTML]="aboutUsData?.approcheDescription"  class="div-text approche-text strapi-text">

            </div>
        </div>
    </div>

</section>

<app-main-footer></app-main-footer>