<app-navigation-bar></app-navigation-bar>

<section class="what-we-do">
    <div [innerHTML]="dataSection?.sectionTitle"  #sectionTitle  class="section-title strapi-text">
         </div>
    <div [innerHTML]="dataSection?.sectionDescription" #sectionDescription  class=" strapi-text section-description">
        
    </div>
    
    <div class="main-container">
        <div class="competences-container">
            <div class="competence">
                <div class="competence-image">
                   <!-- <a routerLink="/architecture-urbanisation" routerLinkActive="active">-->
                    <a>
                        <img src="{{global?.API_URL + dataSection?.archi.image.url}}" alt="">
                    </a>
                </div>
                <a  routerLinkActive="active"><!--routerLink="/architecture-urbanisation"-->
                <div [innerHTML]="dataSection?.archi.title" class="competence-title strapi-text">
                    
                </div>
            </a>
            
                <div [innerHTML]="dataSection?.archi.description" class="competence-description strapi-text">
           
                </div>  
            </div>
            <div  class="competence">
                <div class="competence-image li ">
                   <!-- <a routerLink="/data" routerLinkActive="active">-->
                       <a>
                        <img src="{{global?.API_URL + dataSection?.data.image.url}}"  alt="">
                    </a>
                </div>
                <a  routerLinkActive="active"><!--routerLink="/data"-->
                <div [innerHTML]="dataSection?.data.title" class="competence-title strapi-text">
                    
                </div>
                </a>
                <div [innerHTML]="dataSection?.data.description" class="competence-description strapi-text">
                    

                </div>
            </div>
            <div  class="competence">
                <div class="competence-image li2">
                  <!--   <a routerLink="/cloud" routerLinkActive="active">-->
                      <a>
                        <img src="{{global?.API_URL + dataSection?.cloud.image.url}}"  alt="">
                    </a>
                </div>
            <a  routerLinkActive="active"><!--routerLink="/cloud"-->
                <div [innerHTML]="dataSection?.cloud.title" class="competence-title">
                    
                </div>
            </a>
                <div [innerHTML]="dataSection?.cloud.description" class="competence-description strapi-text">
                    
                </div>
            </div>
            <div  class="competence">
                <div class="competence-image li">
                 <!--    <a routerLink="/industrialisation" routerLinkActive="active">-->
                     <a>
                        <img src="{{global?.API_URL + dataSection?.indu.image.url}}"  alt="">
                        
                    </a>
                </div>
                <a routerLinkActive="active"><!--routerLink="/industrialisation"-->
                <div [innerHTML]="dataSection?.indu.title" class="competence-title strapi-text">
                    
                </div>
            </a>
                <div [innerHTML]="dataSection?.indu.description" class="competence-description strapi-text">
                    
                </div>
            </div>
            <div  class="competence">
                <div class="competence-image li3">
                    <a  ><!--routerLink="/securisation" routerLinkActive="active"-->
                        <img src="{{global?.API_URL + dataSection?.secu.image.url}}"  alt="">
                    </a>
                </div>
                <a   routerLinkActive="active"><!--routerLink="/securisation"-->
                <div [innerHTML]="dataSection?.secu.title" class="competence-title strapi-text">
                    
                </div>
            </a>
                <div [innerHTML]="dataSection?.secu.description" class="competence-description strapi-text fin">
                    
                </div>
            </div>
        </div>
    </div>
</section>

<app-main-footer></app-main-footer>