import { Component ,Renderer2, OnInit,ViewChild } from '@angular/core';
import { Global } from '../../global/global';
import axios from 'axios';

declare var anime:any;

@Component({
  selector: 'app-homepage-chiffres',
  templateUrl: './homepage-chiffres.component.html',
  styleUrls: ['./homepage-chiffres.component.scss'],
  providers:[ Global ]
})
export class HomepageChiffresComponent implements OnInit {
  @ViewChild('chiffreElt') chiffreElt;
  @ViewChild('sectionTitle') sectionTitle;
  @ViewChild('container') container;

  constructor(
    private global:Global,
    private renderer : Renderer2
    ) {
      this.renderer.listen('window','scroll',this.animateOnScroll);
      this.renderer.listen('window','resize',this.animateOnScroll);
     }

  chiffres;
  error=null;
  hasBeenAnimated;
  homepage;
  description;
  numberTitle = [];

  async ngOnInit() {
    try{
      
      const response = await axios.get(this.global.API_URL + "/Homepage-chiffres");
      const title = await axios.get(this.global.API_URL + "/Homepage");
      this.homepage=title.data;
      this.chiffres = response.data;
      this.hasBeenAnimated=false;
    } catch(error){
      this.error = error;
    }
    
  }



  animateOnScroll = (el) => {
    
    if(isInViewport(this.sectionTitle.nativeElement) && ! this.hasBeenAnimated){
      this.animeNumber();
      this.hasBeenAnimated = true;
    }
  }
  

  

 animeNumber = () => {
   let numbersToAnimate = document.querySelectorAll(".number-animate");
   let arrayToAnimate = Array.from(numbersToAnimate);
   for(let i = 0; i < this.chiffres.length; i++){
     arrayToAnimate[i].setAttribute("data-end-value",this.chiffres[i].numero);
   }
   arrayToAnimate.forEach( (el) => {
     
      const endValue = el.getAttribute('data-end-value');
      const incrementValue = el.getAttribute('data-increment');
      const durationValue = el.getAttribute('data-duration');

      if (endValue) this.numberAnimation(el, endValue, incrementValue, durationValue);
      
    });

}



numberAnimation(el, endValue, incrementor, duration) {
  anime({
    targets: el,
    textContent: endValue,
    round: incrementor ? 1/incrementor : 1/5,
    easing: 'easeInOutQuad',
    duration: duration ? duration : 4000,
  });
}


}


function isInViewport (elm) {
  var elementTop = elm.offsetTop;
  var elementBottom = elementTop + elm.offsetHeight;

  var viewportTop = document.documentElement.scrollTop;
  var viewportBottom = viewportTop + document.documentElement.clientHeight;

  return elementBottom > viewportTop && elementTop < viewportBottom;
}