import { Component,Renderer2,ViewChild, OnInit } from '@angular/core';
import { Global } from '../../global/global';
import { Competence} from '../competences';
import axios from 'axios';
import { SwiperConfigInterface} from 'ngx-swiper-wrapper';
import { AppModule } from 'src/app/app.module';
@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss'],
  providers:[ Global,Competence ]
})
export class DataComponent implements OnInit {

  constructor( 
    public global:Global,
    private renderer : Renderer2,
    public competence : Competence
    ) {

     }

     async ngOnInit() {
      try{
        const response = await axios.get(this.global.API_URL + "/data");
        this.competence.data = response.data;
        this.competence.ngOnInit();
      }
      catch(error){

      }
    }

    config:SwiperConfigInterface = this.competence.config;

  
}
