<section class="chiffres">
    <div [innerHTML]="homepage?.chiffresTitle" #sectionTitle class="section-title strapi-text">
    </div>
    <div  #container class="chiffres-container">
         <div *ngFor="let chiffre of chiffres"  class="chiffres-elt">
            <img src="{{global?.API_URL + chiffre?.image.url}}" alt="">
            <h1 data-increment="1" data-duration="3000" class="chiffres-chiffre number-animate">
                1
            </h1>
            <p [innerHTML]="chiffre.description" class="chiffres-text strapi-text">

            </p>

        </div> 
       
    </div>

</section>