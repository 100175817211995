<nav class="navbar">
    <a routerLink="" routerLinkActive="active" class="navbar-brand"><img id="logo-white" [src]="logoWhiteSrc"
            alt=""></a>
    <a routerLink="" routerLinkActive="active" class="navbar-brand"><img id="logo-dark" [src]="logoDarkSrc" alt=""></a>
    <button class="hamburger hamburger--squeeze" type="button">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
    </button>
    <div class="navbar-container">
        <ul class="navbar-list">
            <li class="nav-list-item">
                <div class="dropdown-list">
                    <a class="link-is-disabled is-disabled">
                        <p>{{liens?.presDKT.title}}</p>
                    </a>
                    <div class="dropdown-content">
                        <a routerLink="/about-us" routerLinkActive="active"
                            class="dropdown-content-item">{{liens?.presDKT.adn}}</a>
                        <a routerLink="/vision" routerLinkActive="active" class="dropdown-content-item"
                            href="#">{{liens?.presDKT.valeurs}}</a>
                        <!--<a routerLink="/equipe" routerLinkActive="active" class="dropdown-content-item"
                            href="#">{{liens?.presDKT.equipe}}</a>-->
                    </div>
                </div>
            </li>
            <li class="nav-list-item">
                <div class="dropdown-list">
                    <a routerLink="/what-we-do" routerLinkActive="active" class="is-disabled">
                        <p>{{liens?.presCompetences.title}}</p>
                    </a>
                    <div class="dropdown-content" id="dropdown-activite">
                        <a routerLink="/what-we-do" routerLinkActive="active"
                            class="mobile-link dropdown-content-item">{{liens?.presCompetences.title}}</a>
                     
                    </div>
                </div>
            </li>
            <li class="nav-list-item">
                <div class="dropdown-list">
                    <a class="is-disabled" routerLink="/services" routerLinkActive="active">
                        <p>{{liens?.services.title}}</p>
                    </a>
                    <div class="dropdown-content">
                        <a routerLink="/services" routerLinkActive="active"
                            class="mobile-link dropdown-content-item">{{liens?.services.title}}</a>
                  
                    </div>
                </div>
            </li>
            <!--<li class="nav-list-item">
                <div class="dropdown-list">

                    <a routerLink="/references" routerLinkActive="active">
                        <p>{{liens?.references.title}}</p>
                    </a>
                    <div class="dropdown-content">

                    </div>
                </div>
            </li>-->
            <!-- <li class="nav-list-item">
                <div class="dropdown-list">
                    <a href="http://portail.daka-team.com" routerLinkActive="active">
                        <p>PORTAIL</p>
                    </a>
                    <div class="dropdown-content">

                    </div>
                </div>
            </li> -->
            <li class="nav-list-item">
                <div class="dropdown-list">
                    <a routerLink="/contact" routerLinkActive="active">
                        <p>{{liens?.contact.title}}</p>
                    </a>
                    <div class="dropdown-content">

                    </div>
                </div>
            </li>

            <!--<li class="nav-list-item cta-button">
                <div class="dropdown-list">
                    <a class="cta-button-elt" routerLink="/nous-rejoindre" routerLinkActive="active">
                        <p>{{liens?.rejoindre.title}}</p>
                    </a>
                    <div class="dropdown-content">

                    </div>
                </div>
            </li> -->

            <li class="nav-list-item cta-button">
                <div class="dropdown-list">
                    <a id="my-account" target="_blank" href="http://portail.daka-team.com">
                        <p>Portail</p>
                    </a>
                    <div class="dropdown-content">

                    </div>
                </div>
            </li>
            <a class="" href="#"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 382 382"
                    style="enable-background:new 0 0 382 382;" xml:space="preserve">
                    <path
                        d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889 C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056 H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806 c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1 s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73 c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079 c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426 c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472 L341.91,330.654L341.91,330.654z" />
                </svg>
            </a>
        </ul>

    </div>
</nav>

<div class="modal">
    <ul class="modal-list">

    </ul>
</div>

<div class="modal modal-submenu">
    <svg id="arrow-back" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
        fill="#FFFFFF">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
            d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z" />
    </svg>
    <!-- <svg id="arrow-back" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg> -->
    <ul class="modal-list">

    </ul>
</div>