import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { Global } from '../global/global';
@Component({
  selector: 'app-nous-rejoindre',
  templateUrl: './nous-rejoindre.component.html',
  styleUrls: ['./nous-rejoindre.component.scss'],
  providers : [Global]
})
export class NousRejoindreComponent implements OnInit {

  constructor(
    public global : Global
  ) { }

  nousRejoindre;
  jobs;

  async ngOnInit() {
    const responseNousRejoindre = await axios.get(this.global.API_URL + "/nous-rejoindre");
    this.nousRejoindre = responseNousRejoindre.data;
    const responseJobs = await axios.get(this.global.API_URL + "/jobs");
    this.jobs = responseJobs.data;
  }

}
