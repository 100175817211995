import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'daka-web';
  private recaptchaSiteKey = '6Le6nncbAAAAABT-BzDC4i7aGAb3RV5XNoHzzBvO';

}
