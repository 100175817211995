<app-navigation-bar></app-navigation-bar>
<section class="job">
    <div [innerHTML]="job?.title" class="section-title strapi-text">
    
    </div>
    <div class="container-main">
        <div class="container-mission">
            <div [innerHTML]="job?.missionTitle"  class="mission-title strapi-text">
                
            </div>
            <div [innerHTML]="job?.missionDescription"  class="mission-description strapi-text">
                
            </div>
        </div>
        <div class="container-profil">
            <div [innerHTML]="job?.profilTitle"  class="profil-title strapi-text">
                
            </div>
            <div [innerHTML]="job?.profilDescription"  class="profil-description strapi-text">
                
            </div>
        </div>
        <div class="container-avantage">
            <div  [innerHTML]="job?.avantageTitle"  class="avantage-title strapi-text">
                
            </div>
            <div *ngFor="let avantage of job?.avantage" class="container-avantage-images">
                <div  class="avantage-image">
                    <img src="{{ global.API_URL + avantage?.image?.url}}" alt="">
                </div>
                <div [innerHTML]="avantage.title"  class="avantage-image-text strapi-text">
                    
                </div>
            </div>
        </div>
    </div>
    <div class="cta-button">
            <a class="cta-button-elt" routerLink="/postuler/{{job?.id}}">
                Postuler
            </a>
      
    </div>
 
</section>

<app-main-footer></app-main-footer>