<app-navigation-bar></app-navigation-bar>
<section class="nous-rejoindre">
    <div [innerHTML]="nousRejoindre?.sectionTitle" class="section-title strapi-text">
        
    </div>
    <div [innerHTML]="nousRejoindre?.sectionDescription" class="section-description strapi-text">
        
    </div>
    <div class="container-jobs">
        <div [innerHTML]="nousRejoindre?.jobsTitle" class="jobs-title strapi-text">
            
            </div>
            <div class="container-flex">
                <div *ngFor="let job of jobs" class="job border-theme">
                    <div class="job-image">
                        <img src="{{ global.API_URL + job.image.url}}" alt="">
                    </div>
                    <div class="job-container-description">
                        <div [innerHTML]="job.title" class="job-title strapi-text">
                            
                        </div>
                        <div [innerHTML]="job.description" class="job-pres strapi-text">
                            
                        </div>
                        <div class="job-lien">
                            <a class="button arrow" routerLink="/job/{{job.id}}">
                                En savoir plus
                                
                            </a>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta-button">
                    <a [innerHTML]="nousRejoindre?.boutonPostuler" class="cta-button-elt strapi-text" routerLink="/postuler/{{global.candidature_spontanee_URL}}">
                        
                    </a>

            </div>
            <div class="why-work-with-us">
                <div [innerHTML]="nousRejoindre?.pourquoiTravaillerAvecNousTitle" class="why-work-with-us-title strapi-text">
                    
                </div>
                <div [innerHTML]="nousRejoindre?.pourquoiTravaillerAvecNousDescription" class="why-work-with-us-description strapi-text">
                    

                </div>
            </div>
            <div class="cta-button">
                    <a [innerHTML]="nousRejoindre?.contactBouton" class="cta-button-elt strapi-text" routerLink="/contact" routerLinkActive="active">
                        
                    </a>
                
            </div>
    </div>
</section>

<app-main-footer></app-main-footer>
