<app-navigation-bar></app-navigation-bar>

<section class="cas-client">

    <div class="container-title">

        <div class="container-text">
            <div [innerHTML]="casClient?.sectionTitle" class="section-title strapi-text">
                
            </div>
            <div [innerHTML]="casClient?.sectionDescr" class="section-description strapi-text">
                
            </div>
        </div>
        <div class="container-logo">
            <div class="logo-img">
                <img src="{{global?.API_URL + casClient?.logo.url}}" alt="" class="l">
            </div>
        </div>
    </div>

    


    <div class="container-flex">
        <div class="problematique">
            <div  class="problematique-title">
                <h1 [innerHTML] = "casClient?.problematiqueTitle">
                    
                </h1>
                
            </div>
            <div [innerHTML]="casClient?.problematiqueDescr" class="problematique-descr strapi-text">
                
            </div>
        </div>
        <div class="solutions">
            <div class="solutions-title">
            <h1 [innerHTML]="casClient?.solutionTitle">
                
            </h1>
            </div>
            <div [innerHTML]="casClient?.solutionDescr" class="solutions-descr strapi-text">
                
            </div>
        </div>
    </div>
    <div class="resultats">
        <div class="resultats-title">
            <h1 [innerHTML]="casClient?.resultTitle">
                
            </h1>
        </div>
        <div [innerHTML]="casClient?.resultDescr" class="resultats-descr strapi-text">
            
        </div>
    </div>
    
</section>

<app-main-footer></app-main-footer>