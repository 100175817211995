import { Component, OnInit } from '@angular/core';
import { Global } from '../../global/global';
import axios from 'axios';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperConfigInterface} from 'ngx-swiper-wrapper';


@Component({
  selector: 'app-pres-collab',
  templateUrl: './pres-collab.component.html',
  styleUrls: ['./pres-collab.component.scss'],
  providers : [Global]
})
export class PresCollabComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public global: Global
  ) {
  }

  idCollab;
  collab;
  presCollab;

  async ngOnInit() {
    this.idCollab = this.route.snapshot.paramMap.get('id');
    const responseCollab = await axios.get(this.global.API_URL + '/collaborateurs/' + this.idCollab);
    this.collab = responseCollab.data;
    const responsePres = await axios.get(this.global.API_URL + '/pres-collab');
    this.presCollab = responsePres.data;
    }


  // carrousel
  config: SwiperConfigInterface = {
    slidesPerView: 1,
    scrollbar: false,
    navigation: true,
    loop: true,
    autoplay: {
      delay: 1500
    },
    pagination: {
      clickable: true
    },
    breakpoints: {
      650: {
        slidesPerView: 1,
        autoplay: {
          delay: 2000
        }
      },
      1255: {
        slidesPerView: 3,
        autoplay: {
          delay: 3000
        }
      }
    }
  }
}
