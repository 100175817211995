<app-navigation-bar></app-navigation-bar>

<section class="mention">
    <div [innerHTML]="mentionData?.titre" #valeurTitle class="section-title strapi-text">

    </div>
    
    <div  class="mention-container vie" #container>

        <div  class="container-part bleu">
            <div [innerHTML]="mentionData?.texte1" #texte1Title  class="div-text bleu strapi-text">

            </div>
            
        </div>
 
    </div>
</section>

<app-main-footer></app-main-footer>