<app-navigation-bar></app-navigation-bar>

<section class="pres-collab">
    <div [innerHTML]="collab?.nom" class="section-title  strapi-text">
    
    </div>

    <div class="container-top">

        <div class="container-image">
            <img src='{{global.API_URL + collab?.portrait.url}}' alt="">
            <div class="collab-lien-socials">
                <a *ngIf="collab?.lienLinkedIn" class="" target="_blank" href="{{collab?.lienLinkedIn}}"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 382 382" style="enable-background:new 0 0 382 382;" xml:space="preserve">
               <path d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889 C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056 H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806 c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1 s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73 c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079 c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426 c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472 L341.91,330.654L341.91,330.654z"/> </svg>
               </a>
            </div>
        </div>

        <div class="collab-text">
            <h1 [innerHTML]="collab?.titrePoste"  class="collab-titre  strapi-text">
                
            </h1>
            <div [innerHTML]="collab?.presentation"  class="collab-descr  strapi-text">
                
            </div>
        </div>
        
    <div class="socials">
        <img src="" alt="">
    </div>
    </div>

    <div class="container-bottom">
        <div *ngIf="collab?.competence[0]" class="container-competence">
            <div [innerHTML]="presCollab?.competenceTitle"  class="container-competence-title  strapi-text">
                
            </div>
            <div *ngFor="let comp of collab?.competence" class="container-competence-bar">
                <div class="competence-bar-title">
                    <label [innerHTML]="comp.title"  for="file">
                        :
                    </label>
                    
                </div>
                <div class="competence-bar-bar">
                    <progress id="file" max="100" value="{{comp.valeur}}"> </progress>

                </div>
            </div>
        </div>

        <div *ngIf="collab?.certifImg[0]" class="container-certif">
            <div [innerHTML]="presCollab?.certifTitle"  class="container-certif-title  strapi-text">
                
            </div>

            <div class="div-container">

            <div class="container-certif-container-image">
                <swiper *ngIf="collab" [config]="config" >
                    <ng-template swiperSlide *ngFor="let img of collab?.certifImg">
                        <img class="partenaires-image" [src]="global.API_URL + img.url" alt="">
                    </ng-template>
                    
                  </swiper>
            </div>
        </div>

    </div>
    </div>
</section>

<app-main-footer></app-main-footer>