import { Component, OnInit } from '@angular/core';
import { Global } from '../../global/global';
import axios from 'axios';
import { ActivatedRoute, Router } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
  providers : [Global]
})
export class JobComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    public global : Global
  ) { }

  idJob;
  job;
 
  async ngOnInit() {
    this.idJob = this.route.snapshot.paramMap.get("id");
    const responseJobs = await axios.get(this.global.API_URL + "/jobs/"+this.idJob);
    this.job = responseJobs.data;
    }


}
