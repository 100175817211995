import { Component, Renderer2, ViewChild, OnInit } from '@angular/core';
import { Global } from '../global/global';
import axios from 'axios';
import { SwiperConfigInterface} from 'ngx-swiper-wrapper';



@Component({
  selector: 'app-competence',
  templateUrl: './competence.html',
  styleUrls: ['./competences.scss'],
  providers:[ Global ]
})
export class Competence implements OnInit {

  @ViewChild('sectionTitle') sectionTitle;
  @ViewChild('description') description;
  @ViewChild('partenairesTitle') partenairesTitle;
  @ViewChild('partenairesImage') partenairesImage;
  @ViewChild('partenaires') partenaires;

  constructor(
    private global:Global,
    private renderer : Renderer2
    ) {

     }

    error;
    data;

     async ngOnInit() {
      try{
        this.fillData();
        
        // On a utiliser this.partenaireImage comme modele, mais il est
        // vide donc on le supprime
        this.partenairesImage.nativeElement.remove();
      }
      catch(error){
        this.error=error;
      }
    }

    fillData = () => {
      // Section 
      this.sectionTitle.nativeElement.innerText = this.data.sectionTitle;
      
      // Description
      this.description.nativeElement.innerText = this.data.description;

      // main Image
      let image = document.querySelector(".mainImage img");
      let newUrl = this.global.API_URL + this.data.mainImage.url;
      image.setAttribute("src",newUrl);

      // partenaires
      this.partenairesTitle.nativeElement.innerText = this.data.partenaireTitle;
      let divPartImg = document.querySelector(".partenaires-image");
      this.data.partenaires.forEach(element => {
        let copy =this.createPartenaireImg();
        this.fillPartImg(copy,element);
        let partenaires = document.querySelector(".partenaires");
        divPartImg.insertBefore(copy,this.partenairesImage.nativeElement);
      });

    }

    config:SwiperConfigInterface ={
      slidesPerView: 1,
      scrollbar: false,
      navigation: true,
      loop:true,
       autoplay:{
        delay:1500,
        pauseOnMouseEnter:true
      }, 
      pagination: {
        clickable:true
      },
      breakpoints:{
        1255:{
          slidesPerView:3,
          direction: "vertical",
           autoplay:{
            delay:3000
          } 
        }
      }
    }
    onSwiper(swiper) {
    
    }
    onSlideChange() {
      
    }
    createPartenaireImg = () => {
      let partImg = this.partenairesImage.nativeElement;
      let copy = partImg.cloneNode(true);
      return copy;
    }

    fillPartImg = (copy,partData) => {
      let newUrl = this.global.API_URL + partData.url;
      copy.setAttribute("src",newUrl);
    }

}
