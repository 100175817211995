import { Component, OnInit } from '@angular/core';
import { Global } from '../../global/global';
import axios from 'axios';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  providers:[ Global ]
})
export class HeroComponent implements OnInit {

  constructor(
    private global:Global
    ) {

     }

  homepage;
  error=null;

  async ngOnInit() {

    try{
      const response = await axios.get(this.global.API_URL + "/Homepage");
      this.homepage = response.data;
    } catch(error){
      this.error = error;
    }
    this.setUpBackgroundImage();
  }

  setUpBackgroundImage = () =>  {
    const heroImage = document.querySelector(".hero-image") as HTMLElement;
    const newUrl= this.global.API_URL + this.homepage.heroBackground.url;
    heroImage.style.backgroundImage = `url(${newUrl})`;
  }
  


}

