import { Component, OnInit, ViewChild,ElementRef,Renderer2 } from '@angular/core';
import { Global } from '../global/global';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { NgForm } from '@angular/forms';
import axios from 'axios';
import  './../../../src/assets/smtp';

declare let Email : any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers : [Global]
})
export class ContactComponent implements OnInit {

  @ViewChild('currentCarac') currentCarac:ElementRef; 
  @ViewChild('carac') carac:ElementRef; 
  @ViewChild('prenom') prenom:ElementRef; 
  @ViewChild('nom') nom:ElementRef; 
  @ViewChild('numTel') numTel:ElementRef;
  @ViewChild('email') email:ElementRef;
  @ViewChild('structure') structure:ElementRef;
  @ViewChild('form') form: ElementRef;


  public FormGroup:UntypedFormGroup;
  captchaResolved:boolean = false;
  siteKey = '6LcNzJocAAAAAFH-TleMkEDaHmEMAWC3Nn0A3QtU';


  constructor(
    public global : Global,
    public formBuilder: UntypedFormBuilder,
  ) {
   }

  contact;
  infoDKT;

  async ngOnInit() {
    this.FormGroup = this.formBuilder.group({
      recaptcha : ['',Validators.required],
      email: [null,Validators.required],
      prenom: [null,Validators.required],
      nom: [null,Validators.required],
      numTel: [null,Validators.required],
      structure: [null,Validators.required],
      carac: [null,Validators.required]
    })
    const responseContact = await axios.get(this.global.API_URL + "/contact");
    this.contact = responseContact.data;
    const respinfoDKT = await axios.get(this.global.API_URL + "/info-dkt");
    this.infoDKT = respinfoDKT.data;
    this.capMaxCharacters();
    this.carac.nativeElement.addEventListener('keyup',this.countCharacters,false);

  }

  

  onSubmit(f: NgForm) {
    let checkForm = this.formCheck(f,[this.carac,this.email,this.numTel,this.nom]);
    let checkCaptcha = this.captchaCheck();
    let check = checkForm && checkCaptcha;  
    let currentUrl = window.location.href;
    if( check ){
      Email.send({
        SecureToken:this.global.secureToken,
        To : 'contact@daka-team.com',
        From : this.global.email,
        Subject :  ` Message de ${this.prenom.nativeElement.value} ${this.nom.nativeElement.value}  `,
        Body : `
        Provenance : ${currentUrl} <br>
        Prénom : ${this.prenom.nativeElement.value} <br> 
        Nom : ${this.nom.nativeElement.value}  <br>
        Numéro de téléphone : ${this.numTel.nativeElement.value}  <br>
        Email : ${this.email.nativeElement.value}  <br>
        Structure : ${this.structure.nativeElement.value}  <br>
        Message : ${this.carac.nativeElement.value} <br>
         `
        }).then( message => {alert("Votre message a bien été envoyé."); f.resetForm();  this.form.nativeElement.reset();

        } );
      }
    
  }

 

    fillReqObj(){
      
      let email = this.FormGroup.value.email;
      let prenom = this.FormGroup.value.prenom;
      let nom = this.FormGroup.value.nom;
      let numTel = this.FormGroup.value.numTel;
      let structure = this.FormGroup.value.structure;
      let message = this.FormGroup.value.carac;
      let provenance = window.location.href;

       let reqObj = {
        provenance:provenance,
        email:email,
        prenom: prenom,
        nom:nom,
        numTel:numTel,
        structure:structure,
        message:message
      } 
      return reqObj;
    }

    resolved(response: string) {
      this.captchaResolved = true;
    }
  
    captchaCheck(){
      let captchaText = document.querySelector(".captcha-text");
      if(! this.captchaResolved){
        captchaText.classList.add('display');
      }
      else{
        captchaText.classList.remove('display');
  
      } 
      return this.captchaResolved;
    }

        
      
    formCheck(f: NgForm,eltsToCheck:ElementRef[]){
      let check = true;
      eltsToCheck.forEach(elt =>{
        if( ! elt.nativeElement.value){
          check = false;
          this.displaySiblingInvalideFeedback(elt,true);
        }
        else{
          this.displaySiblingInvalideFeedback(elt,false);
        }
      });
      return check;
    }

  
    notFilledClicked(elt: ElementRef){
      elt.nativeElement.classList.add("not-filled-clicked");
      elt.nativeElement.classList.remove("not-filled");
    }

    displaySiblingInvalideFeedback(elt:ElementRef,check:Boolean){
      let parent = elt.nativeElement.parentElement;
      let invalidFeedback = parent.querySelector(".invalid-feedback");
      if(check){
        invalidFeedback.classList.add("display");
      }
      else{
        invalidFeedback.classList.remove("display");
      }
    }    


    
    capMaxCharacters = () => {
      this.carac.nativeElement.maxLength = this.contact.form.nbCaracMax;
    }

    countCharacters = () => {
      let textEntered = this.carac.nativeElement.value.length;
      this.currentCarac.nativeElement.textContent = textEntered;
    }


}
