<app-navigation-bar></app-navigation-bar>

<section class="nos-services">

    <div [innerHTML]="nosServices?.sectionTitle" class="section-title  strapi-text">
        
    </div>

   <!-- <div class="container-pres-services" >
        <div class="pres-service">
            <a [innerHTML]="liens?.services.conseil" (click)="scroll(conseil)">
                
    
            </a>
            </div>
            <div class="pres-service">
                <a [innerHTML]="liens?.services.expertise" (click)="scroll(expertise)">
                    
        
                </a>
                </div>
                <div class="pres-service">
                    <a [innerHTML]="liens?.services.solutions" (click)="scroll(solutions)">
                        
            
                    </a>
                    </div>
                    <div class="pres-service">
                        <a [innerHTML]="liens?.services.formations" (click)="scroll(formations)">
                            
                
                        </a>
                        </div>
                        
    </div>-->


    <div class="container-bandeau-services">
        <div  id="conseil" #conseil class="bandeau-service">
            <div [innerHTML]="liens?.services.conseil"  class="bandeau-title strapi-text">
                

            </div>
            <div class="bandeau-logo">
                <img src="{{global?.API_URL + nosServices?.conseils.logo.url}}" alt="">
            </div>
            <div [innerHTML]="nosServices?.conseils.description" class="bandeau-description strapi-text">
                
            </div>

        </div>
        <div id="expertise" #expertise class="bandeau-service">

            <div  [innerHTML]="nosServices?.expertise.description"class="bandeau-description strapi-text"></div>
   
            <div class="bandeau-logo">
                <img src="{{global?.API_URL + nosServices?.expertise.logo.url}}" alt="">
            </div>
       
            <div [innerHTML]="liens?.services.expertise" class="bandeau-title strapi-text">
            </div> 

            
        </div>
        <div id="solutions"#solutions  class="bandeau-service">
            <div [innerHTML]="liens?.services.solutions"  class="bandeau-title strapi-text">
                

            </div>
            <div class="bandeau-logo">
                <img src="{{global?.API_URL + nosServices?.solutions.logo.url}}" alt="">
            </div>
            <div [innerHTML]="nosServices?.solutions.description" class="bandeau-description strapi-text">
                

            </div>
        </div>
        
         <div  id="formations"#formations class="bandeau-service">
            <div [innerHTML]="nosServices?.formations.description" class="bandeau-description strapi-text">
            </div>
            <div class="bandeau-logo">
                <img src="{{global?.API_URL + nosServices?.formations.logo.url}}" alt="">
            </div>
            <div [innerHTML]="liens?.services.formations"  class="bandeau-title strapi-text">
            </div>
        </div>
         
    </div>

</section>

<app-main-footer></app-main-footer>